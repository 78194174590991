<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline pb-1">
      <div>
        <b-card-title class="mb-25">
          PPC
        </b-card-title>
        <b-card-text class="mb-0">
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
          /> Your PPC Spend
        </b-card-text>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between mb-50">
          <b-button-group
            class="mr-1"
            size="sm"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Total
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Products
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Brands
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Display
            </b-button>
          </b-button-group>
          <b-button-group
            class="mr-1"
            size="sm"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Day
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Week
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
            >
              Month
            </b-button>
          </b-button-group>
        </div>
      </div>
      <DxChart
        id="orders_chart"
        :data-source="dataSource"
      >
        <DxCommonSeriesSettings
          argument-field="date"
          type="line"
        />
        <DxValueAxis position="left" />
        <DxArgumentAxis>
          <DxLabel
            format="monthAndDay"
          />
        </DxArgumentAxis>
        <DxSeries
          value-field="total"
          type="line"
          :name="totalLegendTitle"
        />
        <DxTooltip
          :enabled="true"
          :shared="true"
          :customize-tooltip="customizeTooltip"
        >
          <DxFormat
            :precision="1"
            type="largeNumber"
          />
        </DxTooltip>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
      </DxChart>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardText, BCardTitle,
} from 'bootstrap-vue'
import {
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxFormat,
  DxLegend,
  DxLabel,
} from 'devextreme-vue/chart'

import Ripple from 'vue-ripple-directive'
import { data } from '@/data/product.ppc'

export default {
  name: 'PpcSpend',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButtonGroup,
    BButton,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTooltip,
    DxArgumentAxis,
    DxFormat,
    DxLegend,
    DxLabel,
  },
  directives: {
    Ripple,
  },
  props: {
    filterParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      total: null,
      aggregationInterval: 'day',
    }
  },
  computed: {
    dataSource() {
      const dataset = []
      data.datasets.forEach(product => {
        data.labels.forEach((label, labelIndex) => {
          if (!dataset[labelIndex]) {
            dataset[labelIndex] = {}
          }

          dataset[labelIndex][`${product.product_id}`] = product.data[labelIndex]
          dataset[labelIndex][`${product.product_id}_display`] = product.dataDisplay[labelIndex]
          dataset[labelIndex].date = new Date(label)
        })
        if (product.product_id === 'total') {
          this.total = product.total.display
        }
      })
      return dataset
    },
    totalLegendTitle() {
      return `Total: ${this.total}`
    },
  },
  methods: {
    customizeTooltip(pointInfo) {
      const items = [
        `Total: ${pointInfo.point.data.total_display}`,
      ]
      const color = pointInfo.point.getColor()

      items.forEach((item, index) => {
        if (item.indexOf(pointInfo.seriesName) === 0) {
          const element = document.createElement('span')

          element.textContent = item
          element.style.color = color
          element.className = 'active'

          items[index] = element.outerHTML
        }
      })

      return { text: items.join('\n') }
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }
</style>
