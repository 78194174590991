<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline pb-1">
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-w-full">
        <div class="tw-flex tw-flex-1 tw-flex-col">
          <div class="tw-text-xl tw-font-bold">
            Orders
          </div>
          <div>Number Of Orders By Hour</div>
        </div>
        <div
          v-if="!isCurrentBreakPointXs"
          class="tw-flex tw-flex-col"
        >
          <div>Data Shown for <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.startDate) }}</span> <span v-if="!isCurrentBreakPointXs">through</span><span v-else>-</span> <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.endDate) }}</span></div>
          <div v-if="compareMode">
            Compared to data for <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.startDate) }}</span> <span v-if="!isCurrentBreakPointXs">through</span><span v-else>-</span> <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.endDate) }}</span>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-col"
        >
          <div><span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.startDate) }}</span> - <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.endDate) }}</span></div>
          <div v-if="compareMode">
            Compared: <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.startDate) }}</span> <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.endDate) }}</span>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex flex-column">
        <div
          v-if="isLoading"
          class="d-flex emulated-flex-gap flex-row justify-content-center align-items-center"
        >
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column flex-fill"
        >
          <div class="d-flex flex-row justify-content-end mb-50 tw-gap-2 tw-items-baseline">
            <DxCheckBox
              v-model="isLabelShow"
              :value="isLabelShow"
              text="Show Labels"
            />
          </div>
          <DxChart
            id="orders_chart"
            :data-source="dataSource"
            :on-legend-click="onLegendClick"
          >
            <DxCommonSeriesSettings
              :ignore-empty-points="true"
              argument-field="hour"
              type="line"
            >
              <DxPoint
                hover-mode="allArgumentPoints"
              />
            </DxCommonSeriesSettings>
            <DxValueAxis position="left" />
            <DxValueAxis
              name="percentage"
              position="right"
            >
              <DxLabel
                format="percent"
              />
            </DxValueAxis>
            <DxArgumentAxis />
            <DxCrosshair
              :enabled="true"
              dash-style="dash"
            >
              <DxHorizontalLine :visible="false" />
            </DxCrosshair>
            <DxSeries
              value-field="orders_count"
              type="bar"
              :name="totalOrdersLegendTitle"
            >
              <DxLabel
                :visible="isLabelShow"
              />
            </DxSeries>
            <DxSeries
              v-if="compareMode"
              value-field="orders_count_compared_period"
              type="bar"
              :name="totalOrdersLegendTitleComparedPeriod"
            >
              <DxLabel
                :visible="isLabelShow"
              />
            </DxSeries>
            <DxSeries
              value-field="percentage"
              axis="percentage"
              :name="totalPercentageLegendTitle"
            >
              <DxLabel
                :visible="isLabelShow"
              />
            </DxSeries>
            <DxSeries
              v-if="compareMode"
              value-field="percentage_compared_period"
              axis="percentage"
              dash-style="dash"
              :name="totalPercentageLegendTitleComparedPeriod"
            >
              <DxLabel
                :visible="isLabelShow"
              />
            </DxSeries>
            <DxTooltip
              :enabled="true"
              :shared="true"
              content-template="tooltipTemplate"
            >
              <DxFormat
                :precision="1"
                type="largeNumber"
              />
            </DxTooltip>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              marker-template="markerTemplate"
            />
            <template #tooltipTemplate="{ data }">
              <OrdersTooltip
                :info="data"
                :date-range="filterParams.dateRange"
                :compare-date-range="filterParams.compareDateRange"
              />
            </template>
            <template #markerTemplate="{ data }">
              <OrdersMarkerTemplate :item="data" />
            </template>
          </DxChart>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BSpinner,
} from 'bootstrap-vue'
import {
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings,
  DxLabel,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxFormat,
  DxLegend, DxPoint, DxCrosshair, DxHorizontalLine,
} from 'devextreme-vue/chart'
import moment from 'moment'
import { DxCheckBox } from 'devextreme-vue/check-box'
import OrdersTooltip from '@/views/trends/components/OrdersTooltip.vue'
import OrdersMarkerTemplate from '@/views/trends/components/OrdersMarkerTemplate.vue'

export default {
  name: 'Orders',
  components: {
    OrdersMarkerTemplate,
    OrdersTooltip,
    DxHorizontalLine,
    DxCrosshair,
    DxPoint,
    DxCheckBox,
    BCard,
    BCardHeader,
    BCardBody,
    BSpinner,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxFormat,
    DxLegend,
  },
  props: {
    filterParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataSource: [],
      total: {},
      aggregationInterval: 'day',
      isLoading: true,
      isLabelShow: true,
      compareMode: false,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    totalOrdersLegendTitle() {
      return `Orders: ${this.total?.orders_count?.display ?? 0}`
    },
    totalOrdersLegendTitleComparedPeriod() {
      return `Orders (Compared Rage): ${this.total?.orders_count?.display ?? 0}`
    },
    totalPercentageLegendTitle() {
      return 'Percentage'
    },
    totalPercentageLegendTitleComparedPeriod() {
      return 'Percentage (Compared Rage)'
    },
  },
  watch: {
    filterParams: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const payload = { ...this.filterParams }

      delete payload.dateRange
      payload.start_date = moment(this.filterParams.dateRange.startDate).format('YYYY-MM-DDT00:00:00')
      payload.end_date = moment(this.filterParams.dateRange.endDate).format('YYYY-MM-DDT23:59:59')

      this.compareMode = !!this.filterParams.compareDateRange?.startDate
      if (!!this.filterParams.compareDateRange?.startDate && this.filterParams.compareDateRange.startDate) {
        payload.compare_start_date = moment(this.filterParams.compareDateRange.startDate).format('YYYY-MM-DDT00:00:00')
        payload.compare_end_date = moment(this.filterParams.compareDateRange.endDate).format('YYYY-MM-DDT23:59:59')
      }
      delete payload.compareDateRange

      this.$store.dispatch('trends/fetchOrders', { params: payload }).then(({ data, total }) => {
        this.dataSource = data ?? []
        this.total = total ?? {}
        this.isLoading = false
      })
    },
    customizeTooltip(pointInfo) {
      const items = [
        `Orders: ${pointInfo.point.data.orders_count_display}`,
        `Percentage: ${pointInfo.point.data.percentage_display}`,
      ]
      const color = pointInfo.point.getColor()

      items.forEach((item, index) => {
        if (item.indexOf(pointInfo.seriesName.substring(0, 6)) === 0) {
          const element = document.createElement('span')

          element.textContent = item
          element.style.color = color
          element.className = 'active tw-text-base tw-font-bold'

          items[index] = element.outerHTML
        } else {
          items[index] = `<span class="tw-text-base">${item}</span>`
        }
      })

      return { text: items.join('\n') }
    },
    onLegendClick(e) {
      const series = e.target
      if (series.isVisible()) {
        series.hide()
      } else {
        series.show()
      }
    },
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }
</style>
