<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline pb-1">
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-w-full">
        <div class="tw-flex tw-flex-1 tw-flex-col">
          <div class="tw-text-xl tw-font-bold">
            Sales
          </div>
          <div>Number Of Units Sold</div>
        </div>
        <div
          v-if="!isCurrentBreakPointXs"
          class="tw-flex tw-flex-col"
        >
          <div>Data Shown for <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.startDate) }}</span> <span v-if="!isCurrentBreakPointXs">through</span><span v-else>-</span> <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.endDate) }}</span></div>
          <div v-if="compareMode">
            Compared to data for <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.startDate) }}</span> <span v-if="!isCurrentBreakPointXs">through</span><span v-else>-</span> <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.endDate) }}</span>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-col"
        >
          <div><span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.startDate) }}</span> - <span class="tw-font-bold">{{ dateFormat(filterParams.dateRange.endDate) }}</span></div>
          <div v-if="compareMode">
            Compared: <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.startDate) }}</span> <span class="tw-font-bold">{{ dateFormat(filterParams.compareDateRange.endDate) }}</span>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex flex-column">
        <div
          v-if="isLoading"
          class="d-flex emulated-flex-gap flex-row justify-content-center align-items-center"
        >
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column flex-fill"
        >
          <div class="d-flex flex-row justify-content-end mb-50 tw-gap-2 tw-items-baseline">
            <DxCheckBox
              v-model="isLabelShow"
              :value="isLabelShow"
              text="Show Labels"
            />
            <aggregation-interval-switch
              v-model="aggregationInterval"
              @value-changed="onAggregationIntervalchanged"
            />
          </div>
          <DxChart
            id="sales_chart"
            class="d-flex flex-column flex-fill m-vh-50"
            :data-source="dataSource"
            :on-legend-click="onLegendClick"
          >
            <DxCommonSeriesSettings
              :ignore-empty-points="false"
              argument-field="date"
              type="line"
            >
              <DxPoint
                hover-mode="allArgumentPoints"
              />
            </DxCommonSeriesSettings>
            <DxValueAxis position="left" />
            <DxValueAxis
              name="aov"
              position="right"
            >
              <DxLabel
                :format="{ style: 'currency', currency: currency, useGrouping: true }"
              />
            </DxValueAxis>
            <DxArgumentAxis
              :aggregation-interval="aggregationInterval"
              argument-type="date"
              tick-interval="day"
              :type="argumentAxisType"
            />
            <DxCrosshair
              :enabled="true"
              dash-style="dash"
            >
              <DxHorizontalLine :visible="false" />
            </DxCrosshair>
            <DxSeries
              value-field="total_units"
              tag="total_units"
              :name="totalUnitsLegendTitle"
            >
              <DxAggregation
                :enabled="true"
                method="sum"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="numberFormat"
              />
            </DxSeries>
            <DxSeries
              v-if="compareMode"
              value-field="total_units_compared_period"
              tag="total_units_compared_period"
              :name="totalUnitsLegendTitleComparedPeriod"
              dash-style="dash"
            >
              <DxAggregation
                :enabled="true"
                method="sum"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="numberFormat"
              />
            </DxSeries>
            <DxSeries
              value-field="average_order_value"
              tag="average_order_value"
              axis="aov"
              :name="averageOrderValueLegendTitle"
            >
              <DxAggregation
                :enabled="true"
                method="average"
              />
              <DxPoint
                :size="1"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="customizeText"
              />
            </DxSeries>
            <DxSeries
              v-if="compareMode"
              value-field="average_order_value_compared_period"
              tag="average_order_value_compared_period"
              axis="aov"
              :name="averageOrderValueLegendTitleComparedPeriod"
              dash-style="dash"
            >
              <DxAggregation
                :enabled="true"
                method="average"
              />
              <DxLabel
                :visible="isLabelShow"
                :customize-text="customizeText"
              />
            </DxSeries>
            <DxTooltip
              :enabled="true"
              :shared="true"
              content-template="tooltipTemplate"
            >
              <DxFormat
                :precision="1"
                type="largeNumber"
              />
            </DxTooltip>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              marker-template="markerTemplate"
            />
            <template #tooltipTemplate="{ data }">
              <SalesTooltip
                :currency="currency"
                :info="data"
                :date-range="filterParams.dateRange"
                :dates-mapping="datesMapping"
                :compare-date-range="filterParams.compareDateRange"
              />
            </template>
            <template #markerTemplate="{ data }">
              <MarkerTemplate :item="data" />
            </template>
          </DxChart>
        </div>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BSpinner,
} from 'bootstrap-vue'
import {
  DxArgumentAxis,
  DxChart,
  DxCommonSeriesSettings,
  DxLabel,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxFormat,
  DxLegend,
  DxAggregation,
  DxPoint,
  DxCrosshair,
  DxHorizontalLine,
} from 'devextreme-vue/chart'
import moment from 'moment'
import { DxCheckBox } from 'devextreme-vue/check-box'
import AggregationIntervalSwitch from '@/components/charts/AggregationIntervalSwitch.vue'
import SalesTooltip from '@/views/trends/components/SalesTooltip.vue'
import MarkerTemplate from '@/views/trends/components/MarkerTemplate.vue'

export default {
  name: 'Sales',
  components: {
    MarkerTemplate,
    SalesTooltip,
    DxCheckBox,
    AggregationIntervalSwitch,
    BCard,
    BCardHeader,
    BCardBody,
    BSpinner,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxFormat,
    DxLegend,
    DxAggregation,
    DxPoint,
    DxCrosshair,
    DxHorizontalLine,
  },
  props: {
    filterParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      total: {},
      aggregationInterval: 'day',
      argumentAxisType: 'discrete',
      isLabelShow: true,
      isLoading: true,
      currency: 'USD',
      dataSource: [],
      compareMode: false,
      datesMapping: {},
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    averageOrderValueLegendTitle() {
      return `Average Order Value: ${this.total?.average_order_value?.display ?? 0}`
    },
    totalUnitsLegendTitle() {
      return `Total Units: ${this.total?.total_units?.display ?? 0}`
    },
    averageOrderValueLegendTitleComparedPeriod() {
      return `Average Order Value (Compared Rage): ${this.total?.average_order_value_compared_period?.display ?? 0}`
    },
    totalUnitsLegendTitleComparedPeriod() {
      return `Total Units (Compared Rage): ${this.total?.total_units_compared_period?.display ?? 0}`
    },
  },
  watch: {
    filterParams: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const payload = { ...this.filterParams }

      delete payload.dateRange
      payload.start_date = moment(this.filterParams.dateRange.startDate).format('YYYY-MM-DDT00:00:00')
      payload.end_date = moment(this.filterParams.dateRange.endDate).format('YYYY-MM-DDT23:59:59')

      this.compareMode = !!this.filterParams.compareDateRange?.startDate
      if (!!this.filterParams.compareDateRange?.startDate && this.filterParams.compareDateRange.startDate) {
        payload.compare_start_date = moment(this.filterParams.compareDateRange.startDate).format('YYYY-MM-DDT00:00:00')
        payload.compare_end_date = moment(this.filterParams.compareDateRange.endDate).format('YYYY-MM-DDT23:59:59')
      }
      delete payload.compareDateRange

      this.$store.dispatch('trends/fetchSales', { params: payload }).then(({ data, total }) => {
        this.dataSource = data?.map(point => {
          const props = { ...point }
          const momentDate = moment(point.date)
          props.date = momentDate.startOf('day').toDate()
          if (point.date_compared_period) {
            const momentDateComparedPeriod = moment(point.date_compared_period)
            props.date_compared_period = momentDateComparedPeriod.startOf('day').toDate()
            this.datesMapping[momentDate.toDate().getTime()] = momentDateComparedPeriod.toDate().getTime()
          }
          return props
        }) ?? []
        this.total = total ?? {}
        if (this.total && this.total.currency) {
          this.currency = this.total.currency
        }
        this.isLoading = false
      })
    },
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    customizeTooltip(pointInfo) {
      const dateEl = document.createElement('span')
      dateEl.textContent = this.dateFormat(pointInfo.argument)
      dateEl.className = 'tw-font-bold tw-text-lg'
      const items = [
        dateEl.outerHTML,
        `Total Units: ${pointInfo.point.aggregationInfo.data[0].total_units_display}`,
        `Average Order Value: ${pointInfo.point.aggregationInfo.data[0].average_order_value_display}`,
      ]
      const color = pointInfo.point.getColor()

      items.forEach((item, index) => {
        // get first 10 characters of series name
        if (item.indexOf(pointInfo.seriesName.substring(0, 10)) === 0) {
          const element = document.createElement('span')

          element.textContent = item
          element.style.color = color
          element.className = 'active tw-font-bold tw-text-base'

          items[index] = element.outerHTML
        } else {
          items[index] = `<span class="tw-text-base">${item}</span>`
        }
      })

      return { text: `<div class="">${items.join('\n')}</div>` }
    },
    onLegendClick(e) {
      const series = e.target
      if (series.isVisible()) {
        series.hide()
      } else {
        series.show()
      }
    },
    customizeText(e) {
      return this.currencyFormatter(e.value)
    },
    numberFormat(e) {
      return new Intl.NumberFormat('en-US').format(e.value)
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency }).format(value)
    },
    onAggregationIntervalchanged(value) {
      if (value === 'day') {
        this.argumentAxisType = 'discrete'
      } else {
        this.argumentAxisType = undefined
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .f-size-25 {
    font-size: 25px;
  }

  .f-size-18 {
    font-size: 18px;
  }

  .v-align-middle {
    vertical-align: middle
  }
</style>
