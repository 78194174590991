<template>
  <section
    id="trends"
    class="tw-flex tw-flex-col"
  >
    <FilterPanel
      class="tw-flex tw-flex-column sticky-position"
      :date-range-value="filters.dateRange"
      :marketplace-value="filters.marketplaces"
      :product-value="filters.products"
      marketplace-filter
      date-range-filter
      @update="onFilterUpdate"
    />
    <div class="tw-flex tw-flex-col">
      <Sales :filter-params="filters" />
      <Orders :filter-params="filters" />
      <Revenue :filter-params="filters" />
      <Profit :filter-params="filters" />
      <PpcSpend
        v-if="false"
        :filter-params="filters"
      />
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Sales from '@/views/trends/widgets/Sales.vue'
import Orders from '@/views/trends/widgets/Orders.vue'
import Revenue from '@/views/trends/widgets/Revenue.vue'
import Profit from '@/views/trends/widgets/Profit.vue'
import PpcSpend from '@/views/trends/widgets/PpcSpend.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import FilterPanel from '@/views/trends/components/FilterPanel.vue'

export default {
  name: 'Trends',
  components: {
    FilterPanel,
    Sales,
    Orders,
    Revenue,
    Profit,
    PpcSpend,
  },
  data() {
    return {
      filters: {
        marketplaces: [],
        products: [],
        compareDateRange: null,
        dateRange: {
          startDate: moment().subtract(7, 'day').startOf('day').toDate(),
          endDate: moment().subtract(1, 'day').endOf('day').toDate(),
        },
      },
    }
  },
  computed: {
    marketplaces() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
  },
  watch: {
    $route(newValue) {
      if (!newValue.query.startDate || !newValue.query.endDate) {
        this.filters.dateRange.startDate = moment().subtract(7, 'day').hour(0).minute(0)
          .second(0)
          .toDate()
        this.filters.dateRange.endDate = moment().hour(23).minute(59).second(59)
          .toDate()
      }
    },
  },
  created() {
    if (this.$route.query.startDate) {
      this.filters.dateRange.startDate = moment(this.$route.query.startDate).toDate()
    }
    if (this.$route.query.endDate) {
      this.filters.dateRange.endDate = moment(this.$route.query.endDate).toDate()
    }
  },
  methods: {
    onFilterUpdate({
      dateRange, marketplaces, products, compareDateRange,
    }) {
      if (dateRange && dateRange.rangeLabel && dateRange.rangeLabel !== 'Today') {
        const query = { ...this.$route.query }
        query.startDate = moment(dateRange.startDate).format('YYYY-MM-DDT00:00:00')
        query.endDate = moment(dateRange.endDate).format('YYYY-MM-DDT23:59:59')
        this.$router.replace({ name: 'trends', query })
      }

      if (compareDateRange) {
        this.filters.compareDateRange = compareDateRange
      }

      this.filters.products = products
      this.filters.marketplaces = marketplaces
      this.filters.dateRange = dateRange
    },
  },
}
</script>

<style lang="scss" scoped>
  .sticky-position {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 5;
  }
</style>
