<template>
  <div class="tw-flex tw-flex-col">
    <div
      v-if="aggregationInfo"
      class="tw-font-bold"
      :class="{ 'tw-text-base' : !hasCompare, 'tw-text-lg' : hasCompare }"
    >
      {{ showDates(aggregationInfo) }}
      <template v-if="hasCompare">
        <div> VS </div>
        <div>{{ showCompareDates(aggregationInfo) }}</div>
      </template>
    </div>
    <div
      v-else
      class="tw-text-lg tw-font-bold"
    >
      {{ dateFormat(info.point.argument) }}
      <template v-if="hasCompare">
        <span> VS </span>
        <span>{{ getVsDate(info.point.argument) }}</span>
      </template>
    </div>
    <div
      class="tw-flex tw-gap-2 tw-text-base"
    >
      <div>Total:</div>
      <div class="tw-flex tw-gap-2 tw-font-bold">
        <div>
          {{ currencyFormatter(pointData.total) }}
        </div>
        <div
          v-if="pointData.total_compared_period"
          class="tw-flex tw-gap-1 tw-items-baseline"
          :class="{
            'text-success': isGrowing(pointData.total, pointData.total_compared_period),
            'text-danger': !isGrowing(pointData.total, pointData.total_compared_period)
          }"
        >
          <FeatherIcon :icon="isGrowing(pointData.total, pointData.total_compared_period) ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
          {{ calculateDiffInPercentages(pointData.total, pointData.total_compared_period) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'RevenueTooltip',
  props: {
    info: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
    compareDateRange: {
      type: Object,
      required: false,
      default: null,
    },
    datesMapping: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pointTitle: 'Total',
    }
  },
  computed: {
    totalData() {
      return this.info.points.find(item => item.point.series.tag === 'total')
    },
    totalCompareData() {
      return this.info.points.find(item => item.point.series.tag === 'total_compared_period')
    },
    hasCompare() {
      return !!this.compareDateRange?.startDate
    },
    aggregationInfo() {
      return this.info.point.aggregationInfo
    },
    pointData() {
      if (!this.info.point.aggregationInfo) {
        return {
          total: this.totalData.value,
          total_compared_period: this.totalCompareData.value,
        }
      }

      let total = 0
      let totalComparedPeriod = 0
      this.info.point.aggregationInfo.data.forEach(data => {
        total += data.total
        totalComparedPeriod += data.total_compared_period || 0
      })

      return {
        total,
        total_compared_period: totalComparedPeriod,
      }
    },
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    isGrowing(valueA, valueB) {
      return valueA > valueB
    },
    calculateDiffInPercentages(valueA, valueB) {
      const diff = valueA - valueB
      let percentage = (diff / valueB) * 100
      if (percentage < 0) {
        percentage *= -1
      }
      return `${percentage.toFixed(2)}%`
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency }).format(value)
    },
    showDates(aggregationInfo) {
      if (aggregationInfo.aggregationInterval === 'day') {
        return this.dateFormat(aggregationInfo.intervalStart)
      }
      const firstElement = aggregationInfo.data[0]
      const lastElement = aggregationInfo.data[aggregationInfo.data.length - 1]
      const startDate = this.dateFormat(firstElement.date)
      const endDate = this.dateFormat(moment(lastElement.date))
      return `${startDate} - ${endDate}`
    },
    showCompareDates(aggregationInfo) {
      if (aggregationInfo.aggregationInterval === 'day') {
        return this.dateFormat(aggregationInfo.intervalStart)
      }

      const firstElement = aggregationInfo.data[0]
      const lastElement = aggregationInfo.data[aggregationInfo.data.length - 1]

      const startDate = this.dateFormat(firstElement.date_compared_period)
      const endDate = this.dateFormat(lastElement.date_compared_period)
      return `${startDate} - ${endDate}`
    },
    getVsDate(date) {
      if (this.datesMapping[date.getTime()]) {
        return this.dateFormat(moment(this.datesMapping[date.getTime()]).toDate())
      }
      return this.dateFormat(date)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
