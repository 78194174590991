// eslint-disable-next-line import/prefer-default-export
export const data = {
  labels: [
    '2020-09-01',
    '2020-10-01',
    '2020-11-01',
    '2020-12-01',
    '2021-01-01',
    '2021-02-01',
    '2021-03-01',
    '2021-04-01',
    '2021-05-01',
    '2021-06-01',
    '2021-07-01',
    '2021-08-01',
  ],
  datasets: [
    {
      product_id: 'total',
      data: [
        207.5,
        500.77,
        525.52,
        507.18,
        462.02,
        417.71,
        522.1,
        595.83,
        746.15,
        920.29,
        1056.34,
        856.83,
      ],
      dataDisplay: [
        '$208 USD',
        '$501 USD',
        '$526 USD',
        '$507 USD',
        '$462 USD',
        '$418 USD',
        '$522 USD',
        '$596 USD',
        '$746 USD',
        '$920 USD',
        '$1,056 USD',
        '$857 USD',
      ],
      total: {
        value: 7318.24,
        display: '$7,318 USD',
      },
    },
  ],
  chartDetailLevel: 'detailed',
  unit: 'month',
}
