<template>
  <b-card no-body>
    <b-card-body
      class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-3"
    >
      <div class="tw-flex tw-flex-row tw-gap-3">
        <div
          v-if="dateRangeError"
          class="date-range-error"
        >
          {{ dateRangeError }}
        </div>
        <div class="tw-flex-1 tw-relative">
          <date-range
            v-model="dateRange"
            :value="dateRange"
            :range-options="rangeOptions"
            @update="onDateRangeChange"
          />
          <label class="flying-label">Date Range</label>
        </div>
        <DxCheckBox
          v-model="showCompare"
          class="lg:tw-hidden md:tw-hidden"
          text="Compare"
          :value="showCompare"
          :disabled="isAllTime"
          :on-value-changed="onShowCompareChange"
        />
      </div>
      <div
        v-if="showCompare"
        class="tw-relative"
      >
        <date-range
          :key="compareDateRangeKey"
          v-model="compareDateRange"
          :value="compareDateRange"
          :range-options="compareRangeOptions"
          @update="onFilterChange"
        />
        <label class="flying-label">Compare Date Range</label>
      </div>
      <div class="tw-flex tw-flex-row tw-gap-3">
        <DxCheckBox
          v-model="showCompare"
          class="tw-hidden md:tw-flex"
          text="Compare"
          :value="showCompare"
          :disabled="isAllTime"
          :on-value-changed="onShowCompareChange"
        />
        <DxTagBox
          v-model="marketplaces"
          class="tw-flex-1"
          :data-source="marketplaceOptions"
          :search-enabled="true"
          :max-displayed-tags="2"
          :show-multi-tag-only="false"
          display-expr="title"
          value-expr="id"
          placeholder="All Marketplaces"
          item-template="item"
          :on-value-changed="onFilterChange"
        >
          <template #item="{ data }">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <div class="flex-1">
                {{ data.title }}
              </div>
              <div>
                <CountryFlag :country="data.country.toLowerCase()" />
              </div>
            </div>
          </template>
        </DxTagBox>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import moment from 'moment'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import DateRange from '@/components/formElements/DateRange.vue'

export default {
  name: 'FilterPanel',
  components: {
    DxTagBox,
    DxCheckBox,
    DateRange,
    BCard,
    BCardBody,
    CountryFlag,
  },
  props: {
    marketplaceValue: {
      type: Array,
      default: () => ([]),
    },
    productValue: {
      type: Array,
      default: () => ([]),
    },
    searchValue: {
      type: String,
      default: null,
    },
    dateRangeValue: {
      type: Object,
      default: () => ({
        startDate: moment().hour(0).minute(0).second(0)
          .toDate(),
        endDate: moment().hour(23).minute(59).second(59)
          .toDate(),
      }),
    },
    dateRangeDropdownValue: {
      type: String,
      default: 'last12months',
    },
    dateRangeDropdownOptions: {
      type: Array,
      default: () => ([]),
    },
    marketplaceFilter: {
      type: Boolean,
      default: false,
    },
    dateRangeFilter: {
      type: Boolean,
      default: false,
    },
    productFilter: {
      type: Boolean,
      default: false,
    },
    dateRangeDropdownFilter: {
      type: Boolean,
      default: false,
    },
    searchFilter: {
      type: Boolean,
      default: false,
    },
    searchFilterPlaceholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      dateRange: {},
      compareDateRange: {},
      marketplaces: [],
      search: undefined,
      timeFilter: undefined,
      products: [],
      showCompare: false,
      rangeOptions: {
        'Last 7 Days': [
          moment().subtract(7, 'day').startOf('day').toDate(),
          moment().subtract(1, 'day').endOf('day')
            .toDate(),
        ],
        'This Month': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        'Last 30 Days': [
          moment().subtract(30, 'day').startOf('day').toDate(),
          moment().subtract(1, 'day').endOf('day')
            .toDate(),
        ],
        'Last 6 Months': [
          moment().subtract(7, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        'Last 12 Months': [
          moment().subtract(13, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year').toDate(),
          moment().subtract(1, 'year').endOf('year').toDate(),
        ],
        'Year to Date': [
          moment().startOf('year').toDate(),
          moment().endOf('day').toDate(),
        ],
        'All Time': [
          moment('2010-01-01', 'YYYY-MM-DD').toDate(),
          moment().endOf('day').toDate(),
        ],
      },
      dateRangeError: undefined,
      selectedRangeKey: 'Last 7 Days',
      compareDateRangeKey: 'justSomeKey',
    }
  },
  computed: {
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    isAllTime() {
      return this.selectedRangeKey === 'All Time'
    },
    compareRangeOptions() {
      const items = {}
      let ranges
      let diffDays
      switch (this.selectedRangeKey) {
        case 'Last 7 Days':
          ranges = {
            'VS Previous 7 Days': [
              moment(this.dateRange.startDate).subtract(7, 'days').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(7, 'day').endOf('day').toDate(),
            ],
            'Vs Same Period Previous Month': [
              moment(this.dateRange.startDate).subtract(1, 'month').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'month').endOf('day').toDate(),
            ],
            'Vs Same Period Previous Year': [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ],
          }
          break
        case 'This Month':
        case 'Last Month':
        case 'Last 30 Days':
          ranges = {
            'Vs Previous Month': [
              moment(this.dateRange.startDate).subtract(1, 'month').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'month').endOf('day').toDate(),
            ],
            'Vs Same Period Previous Year': [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ],
          }
          break
        case 'Last 6 Months':
        case 'Last 12 Months':
        case 'Last Year':
          ranges = {
            'Vs Previous Year': [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ],
          }
          break
        default:
          diffDays = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days')
          if (diffDays <= 7) {
            items['Vs Previous 7 Days'] = [
              moment(this.dateRange.startDate).subtract(7, 'days').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(7, 'day').endOf('day').toDate(),
            ]
            items['Vs Same Period Previous Month'] = [
              moment(this.dateRange.startDate).subtract(1, 'month').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'month').endOf('day').toDate(),
            ]
            items['Vs Same Period Previous Year'] = [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ]
          } else if (diffDays <= 28) {
            items['Vs Previous 30 Days'] = [
              moment(this.dateRange.startDate).subtract(30, 'days').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(30, 'day').endOf('day').toDate(),
            ]
            items['Vs Same Period Previous Month'] = [
              moment(this.dateRange.startDate).subtract(1, 'month').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'month').endOf('day').toDate(),
            ]
            items['Vs Same Period Previous Year'] = [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ]
          } else {
            items['Vs Previous Year'] = [
              moment(this.dateRange.startDate).subtract(1, 'year').startOf('day').toDate(),
              moment(this.dateRange.endDate).subtract(1, 'year').endOf('day').toDate(),
            ]
          }

          ranges = items
      }

      return ranges
    },
  },
  watch: {
    search() {
      this.onFilterChange()
    },
  },
  created() {
    this.dateRange = this.dateRangeValue
    this.marketplaces = this.marketplaceValue
    this.products = this.productValue
    this.search = this.searchValue
    this.timeFilter = this.dateRangeDropdownValue
  },
  methods: {
    onDateRangeChange(data) {
      if (this.selectedRangeKey === data.rangeLabel) {
        this.compareDateRangeKey = `${data.rangeLabel}-${Date.now()}`
      } else {
        this.compareDateRangeKey = data.rangeLabel
      }
      this.selectedRangeKey = data.rangeLabel
      if (this.selectedRangeKey === 'All Time') {
        this.showCompare = false
      } else if (this.showCompare) {
        this.updateCompareDateRange()
      }
      this.onFilterChange()
    },
    onFilterChange() {
      this.dateRangeError = undefined
      if (this.showCompare) {
        const days = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days')
        const compareDays = moment(this.compareDateRange.endDate).diff(moment(this.compareDateRange.startDate), 'days')
        if (days !== compareDays) {
          this.dateRangeError = `${Math.abs(days - compareDays)} of days are mismatched between the two dates`
        }
      }
      this.$emit('update', {
        marketplaces: this.marketplaces,
        dateRange: this.dateRange,
        compareDateRange: this.compareDateRange,
        search: this.search,
        products: this.products,
        timeFilter: this.timeFilter,
      })
    },
    onShowCompareChange() {
      if (this.showCompare) {
        this.updateCompareDateRange()
      } else {
        this.compareDateRange = {}
      }
      this.onFilterChange()
    },
    updateCompareDateRange() {
      const compareRangeOptions = Object.values(this.compareRangeOptions)[0] || []
      if (compareRangeOptions.length === 0) return
      this.compareDateRange = {
        startDate: compareRangeOptions[0],
        endDate: compareRangeOptions[1],
      }
    },
  },
}
</script>

<style lang="css" scoped>
  .date-range-error {
    position: absolute;
    color: var(--danger);
    bottom: 0;
  }
</style>
<style lang="css">
  .flying-label {
    position: absolute;
    top: -12px;
    left: 8px;
    padding: 2px;
    background-color: #fff;
  }
  .dark-layout {
    .flying-label {
      background-color: #283046;
    }
  }
</style>
