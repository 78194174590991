<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-text-md tw-font-bold">
      <div>{{ dateFormat(dateRange.startDate) }} - {{ dateFormat(dateRange.endDate) }} At {{ toAmPm(info.argument) }}</div>
      <template v-if="hasCompare">
        <div>VS</div>
        <div><div>{{ dateFormat(compareDateRange.startDate) }} - {{ dateFormat(compareDateRange.endDate) }} At {{ toAmPm(info.argument) }}</div></div>
      </template>
    </div>
    <div
      class="tw-flex tw-gap-2 tw-text-base"
    >
      <div>Orders:</div>
      <div class="tw-flex tw-gap-2 tw-font-bold">
        <div>
          {{ pointData.orders_count_display }}
        </div>
        <div
          v-if="pointData.orders_count_compared_period"
          class="tw-flex tw-gap-1 tw-items-baseline"
          :class="{
            'text-success': isGrowing(pointData.orders_count, pointData.orders_count_compared_period),
            'text-danger': !isGrowing(pointData.orders_count, pointData.orders_count_compared_period)
          }"
        >
          <FeatherIcon :icon="isGrowing(pointData.orders_count, pointData.orders_count_compared_period) ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
          {{ calculateDiffInPercentages(pointData.orders_count, pointData.orders_count_compared_period) }}
        </div>
      </div>
    </div>
    <div
      class="tw-flex tw-gap-2 tw-text-base"
    >
      <div>Percentage:</div>
      <div class="tw-flex tw-gap-2 tw-font-bold">
        <div>
          {{ pointData.percentage_display }}
        </div>
        <div
          v-if="pointData.orders_count_compared_period && (pointData.percentage - pointData.percentage_compared_period) !== 0"
          class="tw-flex tw-gap-1 tw-items-baseline"
          :class="{
            'text-success': isGrowing(pointData.percentage, pointData.percentage_compared_period),
            'text-danger': !isGrowing(pointData.percentage, pointData.percentage_compared_period)
          }"
        >
          <FeatherIcon :icon="isGrowing(pointData.percentage, pointData.percentage_compared_period) ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
          {{ Math.abs(pointData.percentage - pointData.percentage_compared_period) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'OrdersTooltip',
  props: {
    info: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
    compareDateRange: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      pointTitle: 'Total',
    }
  },
  computed: {
    pointData() {
      return this.info.point.data
    },
    hasCompare() {
      return !!this.compareDateRange?.startDate
    },
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    isGrowing(valueA, valueB) {
      return valueA > valueB
    },
    calculateDiffInPercentages(valueA, valueB) {
      const diff = valueA - valueB
      let percentage = (diff / valueB) * 100
      if (percentage < 0) {
        percentage *= -1
      }
      return `${percentage.toFixed(2)}%`
    },
    toAmPm(hour) {
      if (hour === 0) {
        return '12 AM'
      }
      if (hour < 12) {
        return `${hour} AM`
      }
      if (hour === 12) {
        return '12 PM'
      }
      return `${hour - 12} PM`
    },
  },
}
</script>

<style scoped lang="scss">

</style>
